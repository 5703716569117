import React from 'react';
import Variations from 'alisto.js/lib/components/Variations';
import ProductQuantity from 'alisto.js/lib/components/ProductQuantity';
import currency from 'alisto.js/lib/currency';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';

const Transition = React.forwardRef((props, ref) => <Grow ref={ref} {...props} />);

const ItemDetailsContent = function ({
  i18n,
  item,
  available,
  enableItemObservations,
  enableNumericInputs,
  exitLabel,
  isOpen,
  maxQuantity,
  readonly,
  showCover,
  submitLabel,
  onClose,
  onEnter,
  onQuantityChange,
  onVariationChange,
  totalWithCurrency,
  submitButtonRef,
  onEntered,
  onObservationsChange,
  onSubmit,
}) {
  const renderFooter = () => {
    const submitForm = () => {
      document.getElementById('form-submit').click();
    };

    if (readonly) {
      return (
        <Typography
          color="primary"
          className="my-auto mr-2"
        >
          <strong>
            Total:
            {' '}
            {totalWithCurrency}
          </strong>
        </Typography>
      );
    } if (available) {
      return (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={submitForm}
          ref={submitButtonRef}
        >
          {item.quantity > 0 ? `${submitLabel} ${totalWithCurrency}` : exitLabel}
        </Button>
      );
    }
    return (
      <Button variant="contained" disabled ref={submitButtonRef}>
        {!item.product.inStock ? i18n.outOfStock : i18n.unavailable}
      </Button>
    );
  };

  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  return (
    <>
      {item && isOpen && (
        <Helmet>
          <meta property="og:title" content={item.product.name} />
          <meta property="og:description" content={item.product.description} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={item.product.coverImageUrl} />
          <meta property="product:availability" content={available ? 'in stock' : 'out of stock'} />
          <meta property="product:condition" content="new" />
          <meta property="product:price:amount" content={item.product.price} />
          <meta property="product:price:currency" content={currency} />
          <meta property="product:retailer_item_id" content={item.product.id} />
        </Helmet>
      )}
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        TransitionProps={{ onEnter, onEntered }}
        style={{ zIndex: 2147483643 }}
      >
        <DialogTitle className="pb-0">
          <div className="d-flex justify-content-between">
            <div>{item && item.product.name}</div>
            <div className="my-auto">
              <IconButton
                aria-label="close"
                className="text-secondary float-right p-0 m-0"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {item && (
            <form id="item-details-form" onSubmit={onSubmit}>
              {showCover && item.product.coverImageUrl && (
                <div className="cover-container w-100 text-center pb-3">
                  <img src={item.product.coverImageUrl} className="img-fluid rounded w-100 h-100" style={{ objectFit: 'cover', maxHeight: '400px' }} alt={item.product.name} />
                </div>
              )}
              {item.product.description && item.product.description.length > 0 && (
                <p style={{ whiteSpace: 'break-spaces' }}>{item.product.description}</p>
              )}
              <div>
                <Typography color="secondary">{item.product.textPrice}</Typography>
              </div>
              <Variations
                collection={item.product.variations}
                itemVariations={item.variations}
                onChange={onVariationChange}
                enableNumericInput={enableNumericInputs}
                outOfStockLabel={i18n.outOfStock}
                readonly={readonly}
              />
              {enableItemObservations && !readonly && (
                <TextField
                  id="item-observations"
                  label={i18n.observations}
                  multiline
                  fullWidth
                  value={item.observations || ''}
                  onChange={onObservationsChange}
                  variant="outlined"
                />
              )}
              <input type="submit" id="form-submit" className="d-none" />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          {item && (
            <div className="d-flex justify-content-between pt-3 w-100">
              <div style={{ width: (item.product.enableFractionalSale ? '150px' : '120px') }}>
                {available && (
                  <ProductQuantity
                    max={maxQuantity}
                    value={item.quantity}
                    onChange={onQuantityChange}
                    id="item-quantity"
                    fractional={item.product.enableFractionalSale}
                    unit={item.product.unit}
                    enableNumericInput={enableNumericInputs}
                  />
                )}
              </div>
              {renderFooter()}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ItemDetailsContent;
